var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"CREAR","type":"submit","processing":_vm.processing}}),(!_vm.processing)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"type":"button","variant":"outline-secondary","to":{ name: 'bidders-list' }}},[_vm._v("CANCELAR")]):_vm._e()]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"name"}},[_c('strong',[_vm._v("RAZÓN SOCIAL")])]),_c('validation-provider',{attrs:{"rules":"required","name":"nombre del postor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingrese la razón social del postor ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.names),callback:function ($$v) {_vm.$set(_vm.formData, "names", $$v)},expression:"formData.names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"businessArea"}},[_c('strong',[_vm._v("RUBRO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"rubro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"businessArea","state":errors.length > 0 ? false : null,"options":_vm.options.businessAreas,"placeholder":"Seleccione"},model:{value:(_vm.formData.businessArea),callback:function ($$v) {_vm.$set(_vm.formData, "businessArea", $$v)},expression:"formData.businessArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"ruc"}},[_c('strong',[_vm._v("RUC")])]),_c('validation-provider',{attrs:{"rules":"required|integer|min:11","name":"RUC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","placeholder":"Ingrese el RUC ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.ruc),callback:function ($$v) {_vm.$set(_vm.formData, "ruc", $$v)},expression:"formData.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"phone"}},[_c('strong',[_vm._v("TELÉFONO")])]),_c('validation-provider',{attrs:{"rules":"integer|min:9","name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":"Ingrese teléfono ...","trim":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"email"}},[_c('strong',[_vm._v("CORREO ELECTRÓNICO")])]),_c('validation-provider',{attrs:{"rules":"required|email","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Ingrese el correo electrónico ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.other_emails),function(other,index){return _c('b-row',{key:index,staticClass:"mb-1",attrs:{"cols":"12"}},[(index===0)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"alternate_email"}},[_c('strong',[_vm._v("CORREO ELECTRÓNICO SECUNDARIO")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Los correos secundarios solo recibirán notificaciones'),expression:"'Los correos secundarios solo recibirán notificaciones'",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1",attrs:{"variant":"primary","icon":"AlertCircleIcon"}})],1)]):_vm._e(),_c('b-col',{attrs:{"cols":index === 0 ? '12' : '10',"lg":index === 0 ? '12' : '11'}},[_c('validation-provider',{attrs:{"rules":"email","name":"correo electrónico secundario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("alternate_email-" + (other.id)),"placeholder":"Ingrese el correo electrónico secundario ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(other.email),callback:function ($$v) {_vm.$set(other, "email", $$v)},expression:"other.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2","lg":"1"}},[(index !== 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.removeSecondaryEmail(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)],1)}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addSecondaryEmail()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("AGREGAR OTRO CORREO")])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }